import React from "react";
import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl =
    "https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2FiAAsFLyM9EfWK6UkOLGT%2Fb8786f8-f73d-80d5-b41-66a4b640f446?alt=media&token=bcb5dfba-cd33-4b73-bce4-73e821623d75";
  const fullName = `Ms. Karuna Bhaduri`;
  const zoomUrl =
    "https://us02web.zoom.us/j/5024451180?pwd=bUV1VW5FemhIUUVxODl3ZEdoS3U5QT09";
  const zoomPhoneNumber = "(301) 715-8592";
  const zoomMeetingId = "502 445 1180";
  const zoomMeetingPasscode = "Music";
  const birthDate = new Date(1945, 9 - 1, 25);
  const deathDate = new Date(2021, 5 - 1, 25);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 6 - 1, 27);
  const memorialTime = "6:30-7:30pm India Time | 8am - 9am US Central";
  const inviteText = `Karuna Bhaduri, a loving mother, sister, aunt and grand-aunt, departed to everlasting peace on May 25, 2021, upon completing a life of love and compassion.
  \nWhen we can all be together - we will. And we will sing together to celebrate this amazing woman. For now, in lieu of a physical ceremony, please join us in remembering and celebrating the life of Ms. Bhaduri. 
  \nSo many loved her and she loved so many. 
  \nThe world is less than without her. But, we can take what she taught through her life of giving to make this world a better place. She would have wanted that.`;
  const inviteChild = ``;
  const obituary = ``;
  const storiesRecordUrl = "https://stories.afterword.com/f7a9w40kg";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/r897clyd1kkr7iop0qemjcwzvak6u4gimvvdkl8f";
  const recordingEmbedUrl = "";
  const recordingEmbedDirectUrl = [
    {
      title: "Memorial Slideshow",
      url: "https://storage.googleapis.com/afterword-static/recordings/Maashi-Memorial-Slide-Show_720p.mp4",
    },
    {
      title: "Memorial Recording",
      url: "https://storage.googleapis.com/afterword-static/recordings/Ms%20Karuna%20Bhaduri%20Service.mp4",
    },
  ];
  const dropboxUrl = "";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild={inviteChild}
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      obituaryOverride={"Karuna's Obituary"}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
      dropboxUrl={dropboxUrl}
      // humanModel={{ useJoinFlow: true, pageSlug: "karuna-bhaduri" }}
    ></StoryPage>
  );
};

export default ThisPage;
